/*
 * This component was modified to automatically serve optimized images with lazy loading and reduce file sizes,
 * two types of images can be rendered, fluid images that can change their size, to choose this option the prop <type> must be 'fluid',
 * in the case of fixed images its size does not change, it is set to be 60px width, and the value of prop <type> must be 'fixed'.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

export enum ImageType {
  Fixed = 'fixed',
  Fluid = 'fluid',
}

interface IImageProps {
  src: string;
  type: ImageType;
  alt: string;
  style?: any;
  className?: any;
}

function renderImageFixed(file, className, other) {
  return (
    <Img
      fixed={file.node.childImageSharp.fixed}
      className={`position-relative z-n-1 ${className}`}
      {...other}
      imgStyle={other.style}
    />
  );
}
function renderImageFluid(file, className, other) {
  return (
    <Img
      fluid={file.node.childImageSharp.fluid}
      className={`position-relative z-n-1 ${className}`}
      {...other}
      imgStyle={other.style}
    />
  );
}
const Image = function ({ src, type, className, ...other }: IImageProps) {
  return (
    <StaticQuery
      query={graphql`
        query {
          fixedImages: allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fixed(width: 50, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          fluidImages: allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(
                    sizes: "(max-width: 576px) calc(100vw - 20px), (max-width: 768px) calc(100vw - 40px), 1280px"
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={({ fixedImages, fluidImages }) =>
        type === ImageType.Fixed
          ? renderImageFixed(
              fixedImages.edges.find(image => image.node.relativePath === src),
              className,
              other,
            )
          : renderImageFluid(
              fluidImages.edges.find(image => image.node.relativePath === src),
              className,
              other,
            )
      }
    />
  );
};
export default Image;
